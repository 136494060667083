import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "mails"
    }}>{`Mails`}</h1>
    <h2 {...{
      "id": "zoho"
    }}>{`Zoho`}</h2>
    <h3 {...{
      "id": "email-client-configuration-outlook-apple-mail-thunderbird-etc"
    }}>{`Email client configuration (Outlook, Apple Mail, Thunderbird, etc..)`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Protocol`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Host`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Port`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Mode`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Incoming (IMAP)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`imappro.zoho.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`995`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SSL`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Outgoing (SMTP)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`smtp.zoho.com`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`465`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SSL`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "domain-configuration"
    }}>{`Domain configuration`}</h3>
    <ul>
      <li parentName="ul">{`Add MX records from Zoho`}</li>
    </ul>
    <p>{`Origin Verification (Recommended fo better delivery and avoid spam folder)`}</p>
    <ul>
      <li parentName="ul">{`Verify domain ownership on Zoho`}</li>
      <li parentName="ul">{`Add SPF record from Zoho (Recommended)`}</li>
      <li parentName="ul">{`Add DKIM record from Zoho (Recommended)`}</li>
    </ul>
    <p>{`Add webmail subdomain alias (optional):`}</p>
    <pre><code parentName="pre" {...{}}>{`CNAME webmail.theclient.com business.zoho.com
`}</code></pre>
    <p>{`Add smtp subdomain alias (optional)`}</p>
    <pre><code parentName="pre" {...{}}>{`CNAME mail.theclient.com smtp.zoho.com
`}</code></pre>
    <h3 {...{
      "id": "planes"
    }}>{`Planes`}</h3>
    <h4 {...{
      "id": "plan-gratis"
    }}>{`Plan gratis`}</h4>
    <p>{`No hay IMAP ni POP3, solo webmail.`}</p>
    <h4 {...{
      "id": "plan-1user-mes"
    }}>{`Plan 1$/user mes`}</h4>
    <ul>
      <li parentName="ul">{`Pagado anualmente, contando al admin `}</li>
      <li parentName="ul">{`Se pueden comprar espacio adicional por cada mail.`}</li>
    </ul>
    <h3 {...{
      "id": "crear-registors-txt-en-dns"
    }}>{`Crear registors txt en dns`}</h3>
    <h3 {...{
      "id": "troubleshoot"
    }}>{`Troubleshoot`}</h3>
    <ul>
      <li parentName="ul">{`User block
Si se bloquea la cuenta, este debe ingresar por webmail para desbloquear.`}</li>
      <li parentName="ul">{`Mailer daemon`}</li>
    </ul>
    <h2 {...{
      "id": "mxroute"
    }}>{`MXRoute`}</h2>
    <p><strong parentName="p">{` Service no longer in use `}</strong></p>
    <p>{`Chat de soporte: `}<a parentName="p" {...{
        "href": "https://chat.mxroute.com/channel/support"
      }}>{`https://chat.mxroute.com/channel/support`}</a>{` (Público)`}</p>
    <h4 {...{
      "id": "webmail"
    }}>{`Webmail`}</h4>
    <p><a parentName="p" {...{
        "href": "https://mail.mxlogin.com/"
      }}>{`https://mail.mxlogin.com/`}</a>{`
`}<a parentName="p" {...{
        "href": "https://london.mxroute.com/roundcube"
      }}>{`https://london.mxroute.com/roundcube`}</a>{`
`}<a parentName="p" {...{
        "href": "https://london.mxroute.com/rainloop"
      }}>{`https://london.mxroute.com/rainloop`}</a></p>
    <ul>
      <li parentName="ul">{`Client Area:  (con tech@ecomerciar) púedes ver servicios activos, facturacion, ect.`}</li>
      <li parentName="ul">{`Direct Admin: Para gestionar dominios y mails se debe usar Direct Admin: MXRoute -> Client Area -> Direct Admin.`}</li>
      <li parentName="ul">{`Plan bi-anual, dominios ilimitados, espacio limitado.`}</li>
      <li parentName="ul">{`Armado todo en base a la cuenta gncserviceoeste (esta es la cuenta madre), bajo esta estan varios dominios: (hogarstore, fladimar, otros) `}</li>
      <li parentName="ul">{`Usaba cpanel hasta hace poco, ahora usa Direct Admin`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      